import React from "react"
import { Router, navigate } from '@reach/router'
import loadable from '@loadable/component'
import {RecipeContext} from '../recipecontext.js'

// Suspense doesn't support SSR, so using @loadable/component instead
const RecipeList = loadable(()=>import("../pages/recipelist"));
const Recipe = loadable(()=>import("../components/recipe.js"));

class App extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            doughWeight: null,
            units: "g",
            preferment: false,
            autolysis: false,
            recipeId: null,
            recipe: null,
            currentStep: null,
            handleChange: this.handleChange,
            favourites: null,
            addFavourite: this.addFavourite,
            removeFavourite: this.removeFavourite,
            requestConsent: this.requestConsent,
            setConsent: this.setConsent,
            allowFaves : null
        };
    }

    handleChange = (key,value)=> {
        this.setState({[key]: value});
    }

    // Sets localStorage
    setFavourites = (faves) => { localStorage.setItem("favourites", JSON.stringify(faves)) }
    getFavourites = () => { JSON.parse(localStorage.getItem("favourites")) }

    addFavourite = (recipeId)=>{
        let newFaves = this.state.favourites ? this.state.favourites : [];
        newFaves.push(recipeId);
        if(localStorage.getItem('allowFaves')==='true'){
            this.setState({favourites: newFaves});
            this.setFavourites(newFaves);
            return true;
        } else if(this.requestConsent()){
            this.setState({favourites: newFaves});
            this.setFavourites(newFaves);
            return true;
        } else {
            return false;
        }
    }
    removeFavourite = (index) => {
        let newFaves = this.state.favourites;
        newFaves.splice(index, 1);
        this.setState({favourites: newFaves});
        this.setFavourites(newFaves)
    }

    requestConsent = () => {
        let msg = `eLevn can remember your favourites to enhance your experience - this data will be stored on your device only and will not be used for any other purpose. Do you agree to this?`;
        if(window.confirm(msg)){
            msg = "Great! Favouriting enabled Click 'clear favourites' in the menu at any time to delete your data";
            alert(msg);
            localStorage.setItem('allowFaves','true');
            this.setState({allowFaves: 'true'});
            return true;
        } else {
            msg = "OK! Favouriting is now disabled. Click 'allow favourites' in the menu to re-enable."
            alert(msg);
            localStorage.setItem('allowFaves','false');
            this.setState({allowFaves: 'false'});
            navigate(`/`);
            return false;
        }
    }

    setConsent = (e) => {
        console.log(e.target.attributes.value.value);
        if (e.target.attributes.value.value=== 'true') {
            localStorage.setItem('allowFaves','true');
            this.setState({allowFaves: 'true'});
        }
        else {
            localStorage.removeItem("favourites");
            localStorage.setItem('allowFaves','false');
            this.setState({allowFaves: 'false'});
        }
        let msg = (e.target.attributes.value.value ==='true') ? `Favourites enabled. Click 'clear favourites' in the menu at any time to delete your data` : `Local storage cleared and favouriting disabled. Click 'allow favourites' in the menu to re-enable.`;
        alert(msg);
        navigate(`/`);
    }

    componentDidMount(){
        const faves = JSON.parse(localStorage.getItem("favourites"));
        this.setState({favourites: faves});
    }

    render(){
      return (
          <RecipeContext.Provider value={this.state}>
              <Router>
                    <RecipeList path="/" />
                    <Recipe path="/:recipeId/:weight" />
              </Router>
          </RecipeContext.Provider>
      )}
}
export default App;
